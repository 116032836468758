:root {
	--font-primery: 'SBSansDisplay', 'Arial', sans-serif;
	--row-mb-desktop: 104px;
	--row-mb-mobile: 48px;

	//Updated
	--color-white: #ffffff;
	--color-black: #30373c;
	--color-gray2: rgba(138, 149, 157, 1);
	--color-gray4: #8a959d;
	--color-gray9: #657179;
	--color-green: #1A9E32;
	--color-green2: #15b015;
	--color-gray3: #c0cbd3;
	--color-gray5: #d5dfe6;
	--color-gray6: #f2f5f8;
	--color-gray7: #f2f5f8;
	--color-gray8: #e8eef2;
	--color-red-1: #e64646;
	--color-rgba255: rgba(255, 255, 255, 0.3);
	--color-rgba255-05: rgba(255, 255, 255, 0.5);
	--color-gradientGreen: linear-gradient(104deg, #17d317 0%, #14cc98 100%);
	--color-gradientGreen2: linear-gradient(162deg, #f9ee2f 0%, #f0e405 9.9%, #17d317 37.48%, #17d317 77.23%);
	--color-gradientGreen3: linear-gradient(48deg, #3ce73c 21.66%, #4ad6fc 83.62%);

	//Old
	--color-blue: #00b2df;
	// --color-white2: #f4f4f4;
	//--color-black2: #353850;
	//--color-black3: #222222;
	//--color-gray: #5a636b;
	//--color-half-gray: #e1e1e1;
	//--color-half-gray2: rgba(0, 0, 0, 0.56);
	// --color-half-gray3: rgba(51, 63, 72, 0.8);
}
