html,
body {

    // &.active {
    //     overflow-y: hidden;
    // }
    &.active {
        overflow-y: hidden;
        width: 100%;
        height: 100%;
        z-index: 9999;
    }
}
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    cursor: pointer;
}

::-webkit-scrollbar-track {
    background: var(--s-secondary-c-3);
}

::-webkit-scrollbar-thumb {
    background-color: var(--s-secondary-c-7);
    border-radius: 20px;
	cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
    background-color: var(--s-secondary-c-2);
}

body {
    padding: 0;
    margin: 0;

    font-weight: 400;
    font-family: var(--font-primery);
    font-size: 16px;
    line-height: 22px;
    color: var(--t-primery-c);

    background-color: var(--s-inverted-c);
}

a:hover {
    text-decoration: none;
}

.visually-hidden:not(:focus):not(:active),
input[type='checkbox'].visually-hidden,
input[type='radio'].visually-hidden {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;

    white-space: nowrap;

    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}

.row-mb {
    margin-bottom: var(--row-mb-desktop);

    @media screen and (max-width: 767px) {
        margin-bottom: var(--row-mb-mobile);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
}
