.ns-cookie {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  background-color: var(--s-inverted-c);
  box-shadow: 0 5px 23px -3px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}
.ns-cookie__content {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 15px 0;
}
@media screen and (max-width: 480px) {
  .ns-cookie__content {
    flex-direction: column;
  }
}
.ns-cookie__text {
  font-size: 12px;
  line-height: 130%;
  color: var(--t-descriptive-c-2);
}
.ns-cookie__link {
  color: var(--t-descriptive-c-2);
}
.ns-cookie__button {
  padding: 8px 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: var(--t-inverted-c);
  text-decoration: none;
  text-align: center;
  background-color: var(--s-brand-c);
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  transition: 0.5s;
}
.ns-cookie__button:hover {
  opacity: 0.8;
}