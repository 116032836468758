@font-face {
  font-family: "SBSansDisplay";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/SBSansDisplay-Light.woff2") format("woff2"), url("../fonts/SBSansDisplay-Light.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "SBSansDisplay";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/SBSansDisplay-Regular.woff2") format("woff2"), url("../fonts/SBSansDisplay-Regular.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "SBSansDisplay";
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/SBSansDisplay-SemiBold.woff2") format("woff2"), url("../fonts/SBSansDisplay-SemiBold.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "SBSansDisplay";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/SBSansDisplay-Bold.woff2") format("woff2"), url("../fonts/SBSansDisplay-SemiBold.woff") format("woff");
  font-display: swap;
}
/**
Andy Bell
https://hankchizljaw.com/wrote/a-modern-css-reset/
 */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul,
ol {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul,
ol {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}

:root {
  --font-primery: "SBSansDisplay", "Arial", sans-serif;
  --row-mb-desktop: 104px;
  --row-mb-mobile: 48px;
  --color-white: #ffffff;
  --color-black: #30373c;
  --color-gray2: rgba(138, 149, 157, 1);
  --color-gray4: #8a959d;
  --color-gray9: #657179;
  --color-green: #1A9E32;
  --color-green2: #15b015;
  --color-gray3: #c0cbd3;
  --color-gray5: #d5dfe6;
  --color-gray6: #f2f5f8;
  --color-gray7: #f2f5f8;
  --color-gray8: #e8eef2;
  --color-red-1: #e64646;
  --color-rgba255: rgba(255, 255, 255, 0.3);
  --color-rgba255-05: rgba(255, 255, 255, 0.5);
  --color-gradientGreen: linear-gradient(104deg, #17d317 0%, #14cc98 100%);
  --color-gradientGreen2: linear-gradient(162deg, #f9ee2f 0%, #f0e405 9.9%, #17d317 37.48%, #17d317 77.23%);
  --color-gradientGreen3: linear-gradient(48deg, #3ce73c 21.66%, #4ad6fc 83.62%);
  --color-blue: #00b2df;
}

:root {
  --t-primery-c: var(--color-black);
  --t-accent-c: var(--color-green);
  --t-accent-c-2: var(--color-green2);
  --t-inverted-c: var(--color-white);
  --t-descriptive-c-2: var(--color-gray2);
  --t-descriptive-c-3: var(--color-gray4);
  --t-descriptive-c-4: var(--color-blue);
  --t-descriptive-c-5: var(--color-gray5);
  --t-descriptive-c-6: var(--color-gray9);
  --s-brand-c: var(--color-green2);
  --s-inverted-c: var(--color-white);
  --s-accent-c: var(--color-green);
  --s-secondary-c-1: var(--color-black);
  --s-secondary-c-2: var(--color-gray4);
  --s-secondary-c-3: var(--color-gray5);
  --s-secondary-c-4: var(--color-rgba255);
  --s-secondary-c-5: var(--color-gray2);
  --s-secondary-c-6: var(--color-blue);
  --s-secondary-c-7: var(--color-gray3);
  --s-secondary-c-8: var(--color-gray7);
  --s-secondary-c-9: var(--color-gray6);
  --s-secondary-c-10: var(--color-gray8);
  --s-secondary-c-11: var(--color-rgba255-05);
  --s-gradient-c-1: var(--color-gradientGreen);
  --s-gradient-c-2: var(--color-gradientGreen2);
  --s-gradient-c-3: var(--color-gradientGreen3);
  --error-primery-color: var(--color-red-1);
  --bg-primery-c: var(--color-gray6);
  --text-primery-color: var(--color-black);
  --icon-primery-color: var(--color-black);
}

html.active,
body.active {
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: var(--s-secondary-c-3);
}

::-webkit-scrollbar-thumb {
  background-color: var(--s-secondary-c-7);
  border-radius: 20px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--s-secondary-c-2);
}

body {
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-family: var(--font-primery);
  font-size: 16px;
  line-height: 22px;
  color: var(--t-primery-c);
  background-color: var(--s-inverted-c);
}

a:hover {
  text-decoration: none;
}

.visually-hidden:not(:focus):not(:active),
input[type=checkbox].visually-hidden,
input[type=radio].visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.row-mb {
  margin-bottom: var(--row-mb-desktop);
}
@media screen and (max-width: 767px) {
  .row-mb {
    margin-bottom: var(--row-mb-mobile);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}